<template>
	<Alert :message="error" v-if="error" />
	<div class="container">
		<div class="container-left">
			<div class="vertical-center">
				<div class="left-text">
					<div class="steps">
						<h3><fa icon="check-square" />&nbsp;Add a company</h3>
						<div class="steps-spacer"></div>
					</div>

					<div class="spacer-20"></div>
					<h2>Add a location</h2>
					<div class="spacer-20"></div>
					<p>
						You can add more locations later, but let's start with
						your registered Company address.
					</p>
					<div class="spacer-20"></div>
					<div class="steps">
						<div class="steps-spacer"></div>
						<h3><fa icon="square" />&nbsp;Your profile</h3>
					</div>
					<div class="steps">
						<div class="steps-spacer"></div>
						<h3><fa icon="square" />&nbsp;Add payment</h3>
					</div>
				</div>
			</div>
		</div>

		<div class="container-right">
			<div class="vertical-center">
				<div class="form-container-right">
					<form class="rm-form tile" @submit.prevent="handleSubmit">
						<div class="form-header">Add a company location</div>
						<div class="p-1rem">
							<div class="form-input">
								<label>Location Name:</label>
								<input
									type="text"
									required
									v-model="locationName"
								/>
							</div>
							<div class="form-input">
								<label>Address:</label>
								<input type="text" required v-model="address" />
							</div>
							<div class="form-input">
								<label>State / County:</label>
								<input type="text" required v-model="state" />
							</div>
							<div class="split">
								<div class="form-input">
									<label>Country:</label>
									<select required v-model="country">
										<SelectCountry />
									</select>
								</div>
								<div class="form-input">
									<label>Zip/Post code:</label>
									<input type="text" required v-model="zip" />
								</div>
							</div>

							<div class="form-submit">
								<page-loading v-if="isLoading" />
								<div class="next-btn" v-else>
									<span class="steps">
										<p>Step 2 of 4</p>
									</span>
									<button class="btn-arrow-right">
										<span class="arrow-text"
											>Next Step</span
										>
										<fa class="icon" icon="arrow-right" />
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import SelectCountry from "../../components/SelectCountry";
import { useRouter } from "vue-router";
import useCollection from "../../composables/useCollection";
import {
	addArrayItem,
	projectFirestore,
	timestamp,
} from "../../firebase/config";
import useCookies from "../../composables/useCookies";
import Alert from "../../components/Alert";
import getUser from "../../composables/getUser";
import useDocument from "../../composables/useDocument";
import PageLoading from "../../components/PageLoading.vue";
import { useMeta } from "vue-meta";

export default {
	name: "AddLocation",
	components: { SelectCountry, Alert, PageLoading },
	setup() {
		useMeta({ title: "Signup - Add Location" });
		const router = useRouter();
		const { getCookie } = useCookies();
		const companyId = getCookie("rm_cId");
		const { user } = getUser();

		// Data
		let db = projectFirestore;
		let companyRef = db.collection("companies").doc(companyId);
		let locations = companyRef.collection("locations");
		const {
			error: locationsErr,
			documentId,
			addDoc,
		} = useCollection(locations);

		const { error: updateErr, updateDoc } = useDocument(companyRef);

		// Form refs
		const locationName = ref("");
		const address = ref("");
		const state = ref("");
		const country = ref("");
		const zip = ref("");

		const isLoading = ref(false);
		const error = ref(null);

		const handleSubmit = async () => {
			error.value = false;
			isLoading.value = true;

			const location = {
				locationName: locationName.value.trim(),
				address: address.value,
				state: state.value,
				country: country.value,
				zip: zip.value,
				createdAt: timestamp(),
				users: [user.value.uid],
				manager: user.value.uid,
			};

			await addDoc(location);

			await updateDoc({
				locations: addArrayItem({
					locationName: locationName.value.trim(),
					locationId: documentId.value,
				}),
			});

			if (locationsErr.value || updateErr.value) {
				error.value = "Failed to add location";
				return;
			}

			if (!error.value) {
				router.push({
					name: "AddProfile",
				});
			}
		};

		return {
			locationName,
			address,
			state,
			country,
			zip,
			handleSubmit,
			error,
			isLoading,
		};
	},
};
</script>
