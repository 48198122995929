<template>
	<Alert :message="error" v-if="error" />
	<div class="container">
		<div class="container-left">
			<div class="vertical-center">
				<div class="left-text">
					<div class="steps">
						<h3><fa icon="check-square" />&nbsp;Sign up</h3>
						<div class="steps-spacer"></div>
					</div>

					<div class="spacer-20"></div>

					<h2>Company details</h2>
					<div class="spacer-20"></div>
					<p>
						Add your primary business address with a name that makes
						it easy to identify.
					</p>

					<div class="spacer-20"></div>

					<div class="steps">
						<div class="steps-spacer"></div>
						<h3><fa icon="check-square" />&nbsp;Verify email</h3>
					</div>
				</div>
			</div>
		</div>
		<div class="container-right">
			<div class="vertical-center">
				<div class="form-container-right">
					<form
						class="rm-form tile"
						@submit.prevent="handleSubmit"
						v-if="!isLoading"
					>
						<div class="p-1rem">
							<div>
								<div class="form-input">
									<label>Company Name:</label>
									<input
										name="companyName"
										type="text"
										required
										v-model="companyName"
										placeholder="Risk Memo Ltd"
									/>
								</div>
								<div class="form-input">
									<label>Location Name:</label>
									<input
										type="text"
										required
										v-model="locationName"
										placeholder="Head Office"
									/>
								</div>
							</div>
							<div class="form-input">
								<label>Address:</label>
								<input
									type="text"
									required
									v-model="address"
									placeholder="123 High Street, Suite 200"
								/>
							</div>
							<div class="form-input">
								<label>State / County:</label>
								<input
									type="text"
									required
									v-model="state"
									placeholder="Manchester"
								/>
							</div>

							<div class="form-input">
								<label>Country:</label>
								<select required v-model="country">
									<SelectCountry />
								</select>
							</div>

							<div class="form-input">
								<label>Zip/Post code:</label>
								<input type="text" required v-model="zip" />
							</div>

							<div class="form-submit">
								<div class="next-btn">
									<span class="steps">
										<p>Step 2 of 3</p>
									</span>
									<button class="btn-arrow-right">
										<span class="arrow-text">Next</span>
										<fa class="icon" icon="arrow-right" />
									</button>
								</div>
							</div>
						</div>
					</form>
					<div class="loading-message-container tile" v-else>
						<p>{{ loadingMessage }}</p>
						<br />
						<page-loading />
						<br />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import { projectFirestore, timestamp } from "../../firebase/config";
import useCookies from "../../composables/useCookies";
import getUser from "../../composables/getUser";
import { onBeforeMount } from "@vue/runtime-core";
import Alert from "../../components/Alert.vue";
import PageLoading from "../../components/PageLoading.vue";
import { useMeta } from "vue-meta";
import templateCollection from "../../composables/templateCollection";
import equipmentsCollection from "../../composables/equipmentsCollection";
import { event } from "vue-gtag";
import SelectCountry from "../../components/SelectCountry";

export default {
	name: "AddCompany",
	components: { SelectCountry, Alert, PageLoading },
	setup() {
		useMeta({ title: "Signup - Add Company" });
		const { getCookie, setCookie } = useCookies();
		const companyIdCookie = getCookie("rm_cId");
		const router = useRouter();
		const { user } = getUser();
		const { getAndProcessDefaultTemplates } = templateCollection();
		const { createExampleEquipment } = equipmentsCollection();

		const error = ref(null);
		const companyId = ref(null);
		const isLoading = ref(false);
		const companyName = ref("");
		const locationName = ref("");
		const address = ref("");
		const state = ref("");
		const country = ref("");
		const zip = ref("");
		const loadingMessage = ref("Creating company account");

		// Redirect to dash if a cookie exists
		onBeforeMount(() => {
			if (companyIdCookie) {
				router.push({ name: "TemplatesDash" });
			}
		});

		// Create a company account and add the required components
		const handleSubmit = async () => {
			try {
				error.value = null;
				isLoading.value = true;

				// Create a company document in the companies collection
				const company = {
					companyName: companyName.value.trim(),
					ownerUid: user.value.uid,
					createdAt: timestamp(),
					referral: Rewardful.referral,
					coupon: Rewardful.coupon,
					subscription: {
						status: "free",
						max_contributors: 1,
						max_equipments: 1,
						used_contributors: 1,
						used_equipment: 1,
					},
				};

				const companiesRef = projectFirestore.collection("companies");

				const addCompanyResponse = await companiesRef.add(company);
				loadingMessage.value = "Creating company location";

				if (addCompanyResponse) {
					companyId.value = addCompanyResponse.id;
				} else {
					error.value = "Failed to create company";
					isLoading.value = false;
					return;
				}

				// Add the company ID cookie
				setCookie("rm_cId", companyId.value, "14");

				const companyRef = companiesRef.doc(companyId.value);

				// Create the location sub doc
				const location = {
					locationName: locationName.value.trim(),
					address: address.value.trim(),
					state: state.value.trim(),
					country: country.value.trim(),
					zip: zip.value.trim(),
					createdAt: timestamp(),
					users: [user.value.uid],
					manager: user.value.uid,
				};

				const locations = companyRef.collection("locations");
				const addLocationResponse = await locations.add(location);
				loadingMessage.value = "Creating profile";

				if (!addLocationResponse) {
					error.value =
						"Failed to add location. Please refresh the page.";
					isLoading.value = false;
					return;
				}

				// Batch all of the remaining processes together
				const batch = projectFirestore.batch();

				// Update the company document with the locationId
				const companyUpdates = {
					locations: [
						{
							locationId: addLocationResponse.id,
							locationName: locationName.value.trim(),
						},
					],
				};

				batch.update(companyRef, companyUpdates);

				// Update the user document withthe companyId
				const userUpdates = {
					companyId: companyId.value,
				};

				const userRef = projectFirestore
					.collection("users")
					.doc(user.value.uid);
				batch.update(userRef, userUpdates);

				// Create the owner employee doc
				const employee = {
					role: "Admin",
					owner: true,
					createdAt: timestamp(),
					isActive: true,
					locations: [addLocationResponse.id],
					manages: [addLocationResponse.id],
					profile: {
						email: user.value.email,
						fullName: user.value.displayName,
						jobTitle: "Account Admin",
					},
				};

				if (user.value.photoURL) {
					employee.profile.photoURL = user.value.photoURL;
				}

				const employees = companyRef.collection("employees");
				batch.set(employees.doc(user.value.uid), employee);

				await batch.commit();
				loadingMessage.value = "Loading checklist templates";

				let sampleDataPromises = [];

				// Add the promises to the sampleDataPromises array
				sampleDataPromises.push(getAndProcessDefaultTemplates());

				const equipmentLocation = {
					companyId: companyId.value,
					locationId: addLocationResponse.id,
					locationName: locationName.value.trim(),
				};
				sampleDataPromises.push(
					createExampleEquipment(equipmentLocation)
				);

				try {
					let isLoaded = false;

					setTimeout(() => {
						if (!isLoaded) {
							loadingMessage.value = "Finalizing setup";
						}
					}, 4000);

					await Promise.all(sampleDataPromises);
					isLoaded = true;
				} catch (err) {
					console.error("Could not create example data: ", err);
				}

				event("New Signup", { method: "Web Google signup" });

				router.push({
					name: "VerifyEmail",
					query: { newCompanySignup: "true" },
				});
			} catch (e) {
				error.value = e.message;
				isLoading.value = false;
				return;
			}
		};

		return {
			handleSubmit,
			error,
			isLoading,
			companyName,
			locationName,
			address,
			state,
			country,
			zip,
			loadingMessage,
		};
	},
};
</script>

<style lang="scss" scoped>
.tile {
	min-width: 100%;
}
.rm-form {
	.form-input {
		input {
			&::placeholder {
				color: $rm-grey !important;
			}
		}
	}
}
.loading-message-container {
	text-align: center;
}
</style>
