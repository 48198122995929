<template>
	<Alert :message="error" v-if="error" />
	<div class="container">
		<div class="container-left">
			<div class="vertical-center">
				<div class="left-text">
					<div class="steps">
						<h3><fa icon="check-square" />&nbsp;Add a company</h3>
						<div class="steps-spacer"></div>
					</div>
					<div class="steps">
						<h3><fa icon="check-square" />&nbsp;Add a location</h3>
						<div class="steps-spacer"></div>
					</div>

					<div class="steps">
						<h3><fa icon="check-square" />&nbsp;Your profile</h3>
						<div class="steps-spacer"></div>
					</div>
					<div class="spacer-20"></div>
					<h2>Add billing</h2>
					<div class="spacer-20"></div>
					<p>
						Last step! We just need a payment method to start your
						14-day free trial.
					</p>
				</div>
			</div>
		</div>

		<div class="container-right">
			<div class="vertical-center">
				<div class="form-container-right">
					<form
						class="rm-form tile"
						@submit.prevent="handleSubmit"
						data-rewardful
					>
						<div class="form-header">Create Subscription</div>
						<div class="center-text">
							<b
								><p>
									We won't charge your payment method yet.
								</p></b
							>

							<p>
								We require a payment method to create your
								subscription and start your 14-day free trial.
							</p>
						</div>
						<div class="spacer-40"></div>
						<div class="form-submit">
							<page-loading v-if="isLoading" />
							<div class="next-btn" v-else>
								<span class="steps">
									<!-- <p>Step 4 of 4</p> -->
								</span>
								<button class="btn-arrow-right">
									<span class="arrow-text"
										>Add billing details</span
									>
									<fa class="icon" icon="arrow-right" />
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Alert from "@/components/Alert.vue";
import PageLoading from "../../components/PageLoading.vue";
import EnvUtils from "../../misc/env";
import { useMeta } from "vue-meta";
import { onMounted } from "vue";
import { projectFunctions } from "../../firebase/config";

export default {
	name: "ConfirmSubscription",
	components: { Alert, PageLoading },
	setup() {
		useMeta({ title: "Signup - Confirm subscription" });
		const isLoading = ref(null);
		const error = ref(null);

		const handleSubmit = async () => {
			error.value = null;
			isLoading.value = true;
			// Creates a checkout session to start a new subscription
			const createSub = projectFunctions.httpsCallable(
				"createNewSubscription"
			);

			const successURL = EnvUtils.PORTAL + "/success?subscribed";

			createSub({
				success_url: successURL,
				referral: Rewardful.referral,
			})
				.then((result) => {
					isLoading.value = false;
					window.location.assign(result.data.url);
				})
				.catch((e) => {
					console.error(e);
					isLoading.value = false;
					error.value =
						"Error creating subscription. Please contact us.";
				});
		};

		return {
			handleSubmit,
			isLoading,
			error,
		};
	},
};
</script>

<style lang="scss" scoped>
.center-text {
	text-align: center;
	padding: 1em;
}
</style>
