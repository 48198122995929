<template>
	<Alert :message="error" v-if="error" />
	<div class="container">
		<div class="container-left">
			<div class="vertical-center">
				<div class="left-text">
					<div class="steps">
						<h3><fa icon="check-square" />&nbsp;Add a company</h3>
						<div class="steps-spacer"></div>
					</div>
					<div class="steps">
						<h3><fa icon="check-square" />&nbsp;Add a location</h3>
						<div class="steps-spacer"></div>
					</div>

					<div class="spacer-20"></div>
					<h2>Complete your profile</h2>
					<div class="spacer-20"></div>
					<p>
						Don't worry if you don't have a profile picture to hand,
						you can add it later or take a selfie from the app.
					</p>
					<div class="spacer-20"></div>

					<div class="steps">
						<div class="steps-spacer"></div>
						<h3><fa icon="square" />&nbsp;Add payment</h3>
					</div>
				</div>
			</div>
		</div>

		<div class="container-right">
			<div class="vertical-center">
				<div class="form-container-right">
					<form
						class="rm-form tile"
						@submit.prevent="handleSubmit"
						data-rewardful
						v-if="!redirecting"
					>
						<div class="form-header">Your Employee Profile</div>

						<div id="preview">
							<img v-if="preview" :src="preview" />
							<div class="helper-text" v-if="!preview">
								No Image
							</div>
						</div>

						<div class="p-1rem">
							<div class="form-input">
								<label>Profile Image:</label>
								<input
									name="logo"
									type="file"
									class="custom-file-input-img"
									@change="onFileChange"
								/>
							</div>
							<div class="error-text" v-if="fileError">
								{{ fileError }}
							</div>

							<div class="form-input">
								<label>Full Name:</label>
								<input
									type="text"
									required
									v-model="fullName"
								/>
							</div>

							<div class="form-input">
								<label>Job Title:</label>
								<input
									type="text"
									required
									v-model="jobTitle"
								/>
							</div>

							<div class="form-submit">
								<page-loading v-if="isLoading" />
								<div class="next-btn" v-else>
									<span class="steps">
										<p>Step 3 of 4</p>
									</span>
									<button class="btn-arrow-right">
										<span class="arrow-text"
											>Add Billing Details</span
										>
										<fa class="icon" icon="arrow-right" />
									</button>
								</div>
							</div>
						</div>
					</form>
					<form class="rm-form tile" @submit.prevent v-else>
						<div class="form-header">Redirecting to Stripe...</div>
						<br />
						<PageLoading />
						<div class="spacer-40"></div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useStorage from "../../composables/useStorage";
import {
	timestamp,
	projectAuth,
	projectFirestore,
	projectFunctions,
} from "../../firebase/config";
import useCookies from "../../composables/useCookies";
import getUser from "../../composables/getUser";
import useCollection from "../../composables/useCollection";
import Alert from "@/components/Alert.vue";
import useDocument from "@/composables/useDocument";
import PageLoading from "../../components/PageLoading.vue";
import { useMeta } from "vue-meta";
import templateCollection from "../../composables/templateCollection";
import equipmentsCollection from "../../composables/equipmentsCollection";
import EnvUtils from "../../misc/env";
import router from "@/router/index";

export default {
	name: "AddProfile",
	components: { Alert, PageLoading },
	setup() {
		useMeta({ title: "Signup - Add Profile" });
		const { getCookie } = useCookies();
		const companyId = getCookie("rm_cId");
		const { user } = getUser();
		const { createExampleTemplate } = templateCollection();
		const { createExampleEquipment } = equipmentsCollection();

		// User profile image storage
		const {
			error: storageErr,
			downloadUrl,
			uploadProfileImg,
		} = useStorage();

		// CollectionRef
		let collectionRef = projectFirestore;

		// Set employee doc collectionRef
		let employee = collectionRef
			.collection("companies")
			.doc(companyId)
			.collection("employees");
		const { error: setDocErr, setDoc } = useCollection(employee);

		// Update users collectionRef
		let userDoc = collectionRef.collection("users").doc(user.value.uid);
		const { error: updateDocErr, updateDoc } = useDocument(userDoc);

		// image upload and preview
		const types = ["image/png", "image/jpeg"];
		const file = ref(null);
		const preview = ref(null);
		const fileError = ref(null);

		// form refs
		const fullName = ref("");
		const jobTitle = ref("");
		const isLoading = ref(null);
		const error = ref(null);

		const redirecting = ref(false);

		const handleSubmit = async () => {
			error.value = null;
			isLoading.value = true;

			if (file.value) {
				// upload the user image
				await uploadProfileImg(file.value);

				if (storageErr.value) {
					error.value = "Could not upload image";
					isLoading.value = false;
					return;
				} else {
					// Update the auth profile
					await projectAuth.currentUser.updateProfile({
						photoURL: downloadUrl.value,
					});
					await addCollections();
				}
			} else {
				await addCollections();
			}

			// update the users collection
			const userUpdates = {
				email: user.value.email.trim(),
				fullName: fullName.value.trim(),
				companyId: companyId,
			};

			await updateDoc(userUpdates);

			// add name to authentication
			await projectAuth.currentUser.updateProfile({
				displayName: fullName.value.trim(),
			});

			if (updateDocErr.value) {
				error.value = "Could not add profile";
				isLoading.value = false;
				return;
			}

			// Add example data
			try {
				await createExampleTemplate(EnvUtils.EXAMPLE_CHECKLIST);
				await createExampleEquipment();
			} catch (err) {
				console.error("Could not create example data: ", err);
			}

			if (!error.value) {
				const companyRef = projectFirestore
					.collection("companies")
					.doc(companyId);

				await companyRef.update({
					subscription: {
						status: "free",
					},
				});

				router.push({
					name: "TemplatesDash",
				});
			}

			// if (!error.value) {
			// 	redirecting.value = true;

			// 	// Creates a checkout session to start a new subscription
			// 	const createSub = projectFunctions.httpsCallable(
			// 		"createNewSubscription"
			// 	);

			// 	const successURL = EnvUtils.PORTAL + "/success?subscribed";

			// 	createSub({
			// 		success_url: successURL,
			// 		referral: Rewardful.referral,
			// 	})
			// 		.then((result) => {
			// 			window.location.assign(result.data.url);
			// 			isLoading.value = false;
			// 			redirecting.value = false;
			// 		})
			// 		.catch((e) => {
			// 			console.error(e);
			// 			isLoading.value = false;
			// 			redirecting.value = false;
			// 			error.value =
			// 				"Error creating subscription. Please contact us.";
			// 		});
			// }
		};

		const addCollections = async () => {
			// Get the company location
			const companyDoc = await projectFirestore
				.collection("companies")
				.doc(companyId)
				.get();

			console.log("companyDoc", companyDoc.data());

			const locationId = companyDoc.data().locations[0].locationId;

			const profile = {
				profile: {
					fullName: fullName.value.trim(),
					jobTitle: jobTitle.value.trim(),
					email: user.value.email,
					profileImage: downloadUrl.value,
				},
				role: "Admin",
				createdAt: timestamp(),
				owner: true,
				isActive: true,
				locations: [locationId],
				manages: [locationId],
			};

			console.log("profile", profile);

			await setDoc(user.value.uid, profile);

			if (setDocErr.value) {
				error.value = setDocErr;
			}
		};

		const onFileChange = (e) => {
			const selected = e.target.files[0];

			if (
				selected &&
				types.includes(selected.type) &&
				selected.size < 1000000
			) {
				file.value = selected;
				preview.value = URL.createObjectURL(selected);
				fileError.value = null;
			} else {
				file.value = null;
				fileError.value = "Image must be png/jpg and less than 1mb";
			}
		};

		return {
			handleSubmit,
			fullName,
			jobTitle,
			error,
			isLoading,
			fileError,
			preview,
			onFileChange,
			redirecting,
		};
	},
};
</script>
