<template>
	<Alert :message="error" v-if="error" />
	<div class="container">
		<div class="container-left display-lg">
			<div class="vertical-center">
				<div class="left-text">
					<h2>Complete your profile</h2>
					<div class="spacer-20"></div>
					<p>
						Don't worry if you don't have a profile picture to hand,
						you can add it later or take a selfie from the app.
					</p>
					<div class="spacer-20"></div>
				</div>
			</div>
		</div>

		<div class="container-right">
			<div class="vertical-center">
				<div class="form-container-right">
					<form class="rm-form tile" @submit.prevent="handleSubmit">
						<div class="form-header">Your Employee Profile</div>

						<div id="preview">
							<img v-if="preview" :src="preview" />
							<div class="helper-text" v-if="!preview">
								No Image
							</div>
						</div>

						<div class="p-1rem">
							<div class="form-input">
								<label>Profile Image:</label>
								<input
									name="logo"
									type="file"
									class="custom-file-input-img"
									@change="onFileChange"
								/>
							</div>
							<div class="error-text" v-if="fileError">
								{{ fileError }}
							</div>

							<div class="form-input">
								<label>Full Name:</label>
								<input
									type="text"
									required
									v-model="fullName"
								/>
							</div>

							<div class="form-input">
								<label>Job Title:</label>
								<input
									type="text"
									required
									v-model="jobTitle"
								/>
							</div>

							<div class="form-submit">
								<page-loading v-if="isLoading" />
								<div class="next-btn" v-else>
									<span class="steps">
										<!-- <p>Step 3 of 3</p> -->
									</span>
									<button class="btn-arrow-right">
										<span class="arrow-text">Next</span>
										<fa class="icon" icon="arrow-right" />
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useStorage from "../../composables/useStorage";
import {
	projectFirestore,
	timestamp,
	projectAuth,
	addArrayItem,
} from "../../firebase/config";
import { useRoute, useRouter } from "vue-router";
import useCookies from "../../composables/useCookies";
import getDocumentOnce from "@/composables/getDocumentOnce";
import getUser from "@/composables/getUser";
import useDocument from "@/composables/useDocument";
import useCollection from "@/composables/useCollection";
import Alert from "@/components/Alert";
import { onMounted } from "@vue/runtime-core";
import PageLoading from "../../components/PageLoading.vue";
import { useMeta } from "vue-meta";

export default {
	name: "AddEmployeeProfile",
	components: { Alert, PageLoading },
	setup() {
		useMeta({ title: "Signup - Add Employee Profile" });
		const route = useRoute();
		const router = useRouter();

		// imports
		const { setCookie } = useCookies();
		const { user } = getUser();
		const userDoc = ref(null);
		const error = ref(null);

		const location = [];

		// Collections
		let collectionRef = projectFirestore;

		// image upload and preview
		const types = ["image/png", "image/jpeg"];
		const file = ref(null);
		const preview = ref(null);
		const fileError = ref(null);

		// form
		const fullName = ref("");
		const jobTitle = ref("");
		const isLoading = ref(null);

		// Storage
		const {
			error: storeageErr,
			downloadUrl,
			uploadProfileImg,
		} = useStorage();

		let companyId;

		const inviteDoc = ref();

		let userDocRef = collectionRef.collection("users").doc(user.value.uid);

		// get the companyId from the invitation
		onMounted(async () => {
			// get invitation colelctionRef

			const userRes = await userDocRef.get();

			userDoc.value = userRes.data();

			fullName.value = userDoc.value.fullName;

			let invitation = collectionRef
				.collection("invitations")
				.doc(userDoc.value.invitationId);
			const { error: invitationErr, document } = await getDocumentOnce(
				invitation
			);

			inviteDoc.value = document.value;

			if (invitationErr.value) {
				error.value = "Could not get invitation";
			}
			companyId = inviteDoc.value.companyUid;
			location.push(inviteDoc.value.location.locationId);
		});

		// Update users collectionRef
		const { error: updateDocErr, updateDoc } = useDocument(userDocRef);

		// Update invitation collectionRef
		let updateInvite = collectionRef
			.collection("invitations")
			.doc(route.query.id);
		const { error: updateInviteErr, updateDoc: updateInviteDoc } =
			useDocument(updateInvite);

		const handleSubmit = async () => {
			error.value = null;
			isLoading.value = true;

			if (file.value) {
				// upload the user image
				await uploadProfileImg(file.value);

				if (storeageErr.value) {
					error.value = "Could not upload image";
					isLoading.value = false;
					return;
				} else {
					await projectAuth.currentUser.updateProfile({
						photoURL: downloadUrl.value,
					});
					await addCollections();
				}
			} else {
				await addCollections();
			}

			// update the users collection
			const userUpdates = {
				fullName: fullName.value.trim(),
				companyId: companyId,
			};
			await updateDoc(userUpdates);

			// add name to authentication
			await projectAuth.currentUser.updateProfile({
				displayName: fullName.value,
			});

			if (updateDocErr.value) {
				error.value = "An unknown error occured";
				isLoading.value = false;
				return;
			}

			if (!error.value) {
				setCookie("rm_cId", companyId, "14");
				const inviteUpdate = {
					status: "Accepted",
				};
				await updateInviteDoc(inviteUpdate);
				router.push({ name: "VerifyEmail" });
			}
		};

		const addCollections = async () => {
			// create an employee profile
			const profile = {
				profile: {
					fullName: fullName.value.trim(),
					jobTitle: jobTitle.value.trim(),
					email: user.value.email,
					profileImage: downloadUrl.value,
				},
				role: "Employee",
				createdAt: timestamp(),
				isActive: true,
				manages: [],
				locations: location,
			};

			// Set employee doc collectionRef
			let employee = collectionRef
				.collection("companies")
				.doc(companyId)
				.collection("employees");
			const { error: setDocErr, setDoc } = useCollection(employee);

			await setDoc(user.value.uid, profile);

			// add the user to the location
			let locationUpdate = collectionRef
				.collection("companies")
				.doc(companyId)
				.collection("locations")
				.doc(inviteDoc.value.location.locationId);
			const { updateDoc, error: updateDocErr } =
				useDocument(locationUpdate);

			let update = {
				users: addArrayItem(user.value.uid),
			};

			await updateDoc(update);

			if (setDocErr.value || updateDocErr.value) {
				isLoading.value = false;
				error.value = "Could not create profile";
				return;
			}
		};

		const onFileChange = (e) => {
			const selected = e.target.files[0];

			if (
				selected &&
				types.includes(selected.type) &&
				selected.size < 1000000
			) {
				file.value = selected;
				preview.value = URL.createObjectURL(selected);
				fileError.value = null;
			} else {
				file.value = null;
				fileError.value = "Image must be png/jpg and less than 1mb";
			}
		};

		return {
			handleSubmit,
			fullName,
			jobTitle,
			error,
			isLoading,
			fileError,
			preview,
			onFileChange,
		};
	},
};
</script>

<style lang="scss" scoped>
.display-lg {
	display: none;

	@include respond(medium) {
		display: block;
	}
}
</style>
